import { JwtAxios } from '@/packaging';

export const lotteonApi = {
  async GetLotteOnApiTemplate(marketAccount) {
    const res = await JwtAxios.get(
      `/Template/Market/GetLotteOnApiTemplate?marketAccount=${marketAccount}`
    );
    return res;
  },
  async GetLotteOnApiShipment(marketAccount, marketIdx) {
    const res = await JwtAxios.get(
      `/Template/Market/GetLotteOnApiShipment?marketAccount=${marketAccount}&marketIdx=${marketIdx}`
    );
    return res;
  },
  async GetLotteOnApiShipmentPolicyList(marketAccount, marketIdx) {
    const res = await JwtAxios.get(
      `/Template/Market/GetLotteOnApiShipmentPolicyList?marketAccount=${marketAccount}&marketIdx=${marketIdx}`
    );
    return res;
  },
  async GetLotteOnApiJejuEtcShipmentPolicyList(marketAccount, marketIdx) {
    const res = await JwtAxios.get(
      `/Template/Market/GetLotteOnApiJejuEtcShipmentPolicyList?marketAccount=${marketAccount}&marketIdx=${marketIdx}`
    );
    return res;
  },
  async GetLotteOnApiReturnExchange(marketAccount, marketIdx) {
    const res = await JwtAxios.get(
      `/Template/Market/GetLotteOnApiReturnExchange?marketAccount=${marketAccount}&marketIdx=${marketIdx}`
    );
    return res;
  },
  async SetLotteOnApi(model) {
    const res = await JwtAxios.post(`/Template/Market/SetLotteOnApi`, model);
    return res;
  },
  async SetLotteOnApiTemplate(body) {
    const res = await JwtAxios.post('/Template/Market/SetLotteOnApiTemplate', body);
    return res;
  }
};
