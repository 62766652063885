<template>
  <div class="alert_conatiner" ref="alert">
    <div class="alert_header">
      <ul class="tab_menu_group">
        <li class="tab_menu" @click="tab = 'alarm'" :class="{ selected: tab === 'alarm' }">
          <svg_alert />
          <h3 class="tab_name">알림</h3>
        </li>
        <div class="divider">|</div>
        <li
          class="tab_menu"
          @click="tab = 'work_status'"
          :class="{ selected: tab === 'work_status' }"
        >
          <svg_work_status />
          <h3 class="tab_name">작업현황</h3>
        </li>
        <div class="divider">|</div>
        <li class="tab_menu" @click="tab = 'log'" :class="{ selected: tab === 'log' }">
          <svg_log />
          <h3 class="tab_name">로그</h3>
        </li>
      </ul>
      <div class="default_button_group">
        <button class="close_button" @click="$emit('closeAlert')"><svg_close /></button>
      </div>
    </div>
    <Alarm v-if="tab === 'alarm'" />
    <WorkStatus v-if="tab === 'work_status'" />
    <Log v-if="tab === 'log'" />
  </div>
</template>

<script>
import { workerApi } from '@/api';

import svg_alert from '@/components/svg/svg_alert';
import svg_work_status from '@/components/svg/svg_work_status';
import svg_log from '@/components/svg/svg_log';
// import svg_my_payment from '@/components/svg/svg_my_payment';
import svg_close from '@/components/svg/svg_close';

import Alarm from './Alarm';
import WorkStatus from './WorkStatus';
import Log from './Log';

export default {
  components: {
    svg_alert,
    svg_work_status,
    svg_log,
    // svg_my_payment,
    svg_close,
    WorkStatus,
    Log,
    Alarm
  },
  data() {
    return {
      tab: 'alarm'
    };
  },
  created() {
    workerApi.getWorkerStatus(this.socketStore.connectionId).then(res => {
      if (res.status === 'Success') {
        this.socketStore.setStatus(res.data);
      }
      if (res.data !== null) {
        if (['COLLECT', 'UPLOAD', 'STOCKLINK', 'PAUSE'].includes(res.data)) {
          workerApi.getWorkList(this.socketStore.connectionId).then(workList => {
            this.socketStore.setWorkList(workList.data);
          });
        }
        if (['COLLECT', 'UPLOAD', 'STOCKLINK'].includes(res.data)) {
          this.socketStore.setType(res.data);
        }
      }
    });
  },
  destroyed() {
    this.socketStore.setFinish('');
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/alert.scss';
</style>
