import { JwtAxios } from '@/packaging';

export const tmonApi = {
  async GetTmonTemplate(marketAccount) {
    const responseModel = await JwtAxios.get(
      `/Template/market/GetTmonTemplate?marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async GetTmonShipmentPolicy(connectionId, marketAccount, marketIdx, shipmentPolicyNo) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetTmonShipmentPolicy?connectionId=${connectionId}&marketAccount=${marketAccount}&marketIdx=${marketIdx}&shipmentPolicyNo=${shipmentPolicyNo}`
    );
    return responseModel;
  },
  async GetTmonShipmentPolicyList(connectionId, marketAccount, marketIdx) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetTmonShipmentPolicyList?connectionId=${connectionId}&marketAccount=${marketAccount}&marketIdx=${marketIdx}`
    );
    return responseModel;
  },
  async SetTmonAccount(connectionId, model) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/SetTmonAccount?connectionId=${connectionId}`,
      model
    );
    return responseModel;
  },
  async SetTmonTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetTmonTemplate`, model);
    return responseModel;
  }
};
