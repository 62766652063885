import { JwtAxios } from '@/packaging';

export const dashboardApi = {
  async GetLast7DaysOrderInfo() {
    const res = await JwtAxios.get(`/Dashboard/GetLast7DaysOrderInfo`);
    return res;
  },
  async GetInformations(type) {
    const res = await JwtAxios.get(`/Dashboard/GetInformations?type=${type}`);
    return res;
  },
  async GetInformationsMore(type, page, maxRow) {
    const res = await JwtAxios.get(
      `/Dashboard/GetInformationsMore?type=${type}&page=${page}&maxRow=${maxRow}`
    );
    return res;
  },
  async CheckPcc(body) {
    const res = await JwtAxios.post('/Dashboard/CheckPcc', body);
    return res;
  },
  async GetPopupNoticeList() {
    const res = await JwtAxios.get('/Dashboard/GetPopupNoticeList');
    return res;
  }
};
