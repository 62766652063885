import { defineStore } from 'pinia';

export const useCollectStore = defineStore({
  id: 'collectStore',
  state: () => ({
    searchRandomKeyword: '',
    shoppingKeyword: [],
    advertiseKeyword: []
  }),
  actions: {
    setRandomKeyword({ text, shopping, advertise }) {
      this.searchRandomKeyword = text;
      this.shoppingKeyword = shopping;
      this.advertiseKeyword = advertise;
    }
  }
});
