<template>
  <div class="work_status">
    <div class="work_status_progress">
      <p class="progress_time" :class="{ not_working: socketStore.progress === 0 }">
        {{ progressTime }}
      </p>
      <div class="progress_button_group">
        <button class="remuse" @click="changeStatus('RESUME')" :disabled="disabledButton">
          재시작
        </button>
        <button class="reset" @click="changeStatus('PAUSE')" :disabled="disabledButton">
          일시정지
        </button>
        <button class="stop" @click="changeStatus('STOP')" :disabled="disabledButton">중지</button>
        <button v-if="!['UNKNOWN', 'WAIT'].includes(socketStore.status)" class="move_upload_page">
          업로드 페이지 이동
        </button>
      </div>
    </div>
    <div class="work_status_body">
      <div v-if="socketStore.finish === ''">
        <div class="no_work" v-if="['UNKNOWN', 'WAIT'].includes(socketStore.status)">
          <img src="/assets/images/app/layout/alert/cloud.png" alt="cloud" />
          <p class="no_work_message">진행중인 작업이 없습니다.</p>
        </div>
        <div class="ongoing" v-else>
          <img src="/assets/images/app/layout/alert/scanning.gif" alt="work_status" width="100" />
          <p class="status_message">{{ workStatus }}</p>
        </div>
      </div>

      <div v-else class="complete_socket">
        <div class="spinner"></div>
        <img src="/assets/images/app/layout/alert/good.png" alt="complete" />
        <p class="complete_message">완료되었습니다.</p>
        <p class="additional_message">결과는 {{ statusType }} 페이지에서 확인하시기 바랍니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { workerApi } from '@/api';
export default {
  data() {
    return {
      statusType: '상품 수집'
    };
  },
  methods: {
    async changeStatus(status) {
      if (status === 'PAUSE') {
        this.socketStore.setProgress('pause');
        await workerApi
          .changeStatus({
            connectionId: this.socketStore.connectionId,
            type: this.socketStore.type,
            status
          })
          .then(() => this.socketStore.setStatus('PAUSE'));
      } else if (status === 'RESUME') {
        this.socketStore.setProgress('start');
        await workerApi
          .changeStatus({
            connectionId: this.socketStore.connectionId,
            type: this.socketStore.type,
            status
          })
          .then(() => this.socketStore.setStatus(this.socketStore.type));
      } else if (status === 'STOP') {
        this.common.setDialog({
          subTitle: `${this.statusType}를 중단하시겠습니까?`,
          button: {
            content: '중단',
            color: 'red',
            onClick: async () => {
              this.common.setLoading();
              await workerApi
                .changeStatus({
                  connectionId: this.socketStore.connectionId,
                  type: this.socketStore.type,
                  status
                })
                .then(() => {
                  setTimeout(() => {
                    this.socketStore.setCollectList(true);
                    this.socketStore.setUploadList(true);
                    this.socketStore.setProgress('stop');
                    this.socketStore.setStatus('WAIT');
                    this.socketStore.setType('');
                    this.common.setLoading();
                  }, 2000);
                });
            }
          }
        });
      }
    }
  },
  computed: {
    workStatus() {
      let message =
        this.statusType + ['COLLECT', 'UPLOAD', 'STOCKLINK'].includes(this.socketStore.status)
          ? ' 진행 중'
          : ' 일시정지';

      return message;
    },
    progressTime() {
      if (this.socketStore.progress === 0) {
        return '0초 진행 중';
      } else {
        const { progress } = this.socketStore;
        const hour = progress > 3659 ? Math.floor(progress / 3600) : 0;
        const minutes = progress > 59 ? Math.floor(progress / 60) : 0;
        const seconds = progress > 59 ? progress % 60 : progress;
        const result = `${hour > 0 ? hour + ' 시간 ' : ' '}${minutes > 0 ? minutes + ' 분 ' : ' '}${
          seconds + ' 초'
        }`;
        if (this.socketStore.finish !== '') {
          return `${result} 진행 완료`;
        } else {
          return `${result} 진행 중`;
        }
      }
    },
    disabledButton() {
      return (
        !['COLLECT', 'UPLOAD', 'STOCKLINK', 'UPDATE', 'PAUSE'].includes(this.socketStore.status) ||
        this.socketStore.finish !== ''
      );
    }
  },
  watch: {
    'socketStore.type': {
      deep: true,
      handler(type) {
        this.statusType =
          type === 'COLLECT' ? '상품 수집' : type === 'UPLOAD' ? '상품 업로드' : '재고 연동';
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/workStatus.scss';
</style>
