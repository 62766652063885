import { JwtAxios } from '@/packaging';

export const workerApi = {
  async getWorkerStatus(connectionId) {
    const res = await JwtAxios.get(`/Worker/GetWorkerStatus?connectionId=${connectionId}`);
    return res;
  },
  async getWorkList(connectionId) {
    const res = await JwtAxios.get(`/Worker/GetWorkList?connectionId=${connectionId}`);
    return res;
  },
  async changeStatus({ connectionId, type, status }) {
    const res = await JwtAxios.get(
      `/Worker/ChangeStatus?connectionId=${connectionId}&type=${type}&status=${status}`
    );
    return res;
  }
};
