import Vue from 'vue';
import VueRouter from 'vue-router';
import Applayout from '@/components/layout/app';
import Userlayout from '@/components/layout/user';
import NotFound from '@/views/NotFound.vue';
import { useAuthStore } from '@/stores/auth';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    component: () => import('./views'),
    meta: { auth: false }
  },
  {
    path: '/app',
    component: Applayout,
    redirect: '/app',
    children: [
      {
        path: '/',
        name: '메인',
        component: () => import('./views/app/index'),
        meta: { auth: true, title: '메인화면', subTitle: '' }
      },
      {
        path: 'notice',
        name: '공지사항',
        component: () => import('./views/app/notice'),
        meta: { auth: true, title: '업데이트 및 공지사항', subTItle: '' }
      },
      {
        path: 'productSetting',
        name: '상품수집설정',
        component: () => import('./views/app/productSetting'),
        meta: { auth: true, title: '상품수집설정', subTitle: '' }
      },
      {
        path: 'standbyProduct',
        name: '대기상품관리',
        component: () => import('./views/app/standbyProduct'),
        meta: { auth: true, title: '대기상품관리', subTitle: '' }
      },
      {
        path: 'ProductManagement',
        name: '상품수집관리',
        component: () => import('./views/app/productManagement'),
        meta: { auth: true, title: '상품수집관리', subTitle: '' }
      },
      {
        path: 'registeredProduct',
        name: '등록상품관리',
        component: () => import('./views/app/registeredProduct'),
        meta: { auth: true, title: '등록상품관리', subTitle: '' }
      },
      {
        path: 'inventoryLinked',
        name: '재고연동',
        component: () => import('./views/app/inventoryLinked'),
        meta: { auth: true, title: '메인화면', subTitle: '' }
      },

      {
        path: 'orderManagement',
        name: '주문관리',
        component: () => import('./views/app/orderManagement'),
        meta: { auth: true, title: '주문관리', subTitle: '' }
      },
      {
        path: 'statistics',
        name: '통계분석',
        component: () => import('./views/app/statistics'),
        meta: { auth: true, title: '통계분석', subTitle: '' }
      },

      {
        path: 'preferences',
        name: '환경설정',
        component: () => import('./views/app/preferences'),
        meta: { auth: true, title: '환경설정', subTitle: '' }
      },
      {
        path: '/test',
        name: '테스트',
        component: () => import('./views/test/index'),
        meta: { auth: true, title: '메인화면', subTitle: '' }
      }
    ]
  },
  {
    path: '/plan',
    component: Applayout,
    redirect: '/plan',
    children: [
      {
        path: '/',
        name: '결제관리',
        component: () => import('./views/plan'),
        meta: { auth: true, title: '결제관리', subTitle: '' }
      }
    ]
  },
  {
    path: '/app/template',
    component: Applayout,
    redirect: '/app/template',
    children: [
      {
        path: 'image',
        name: '이미지 템플릿',
        component: () => import('./views/app/template/image'),
        meta: { auth: true, title: '이미지 템플릿', subTitle: '' }
      },
      {
        path: 'upload',
        name: '업로드 템플릿',
        component: () => import('./views/app/template/upload'),
        meta: { auth: true, title: '업로드 템플릿', subTitle: '' }
      },
      {
        path: 'word',
        name: '단어 관리 템플릿',
        component: () => import('./views/app/template/word'),
        meta: { auth: true, title: '단어 관리 템플릿', subTitle: '' }
      },
      {
        path: 'market',
        name: '마켓 설정 템플릿',
        component: () => import('./views/app/template/market'),
        meta: { auth: true, title: '단어 관리 템플릿', subTitle: '' }
      }
    ]
  },

  {
    path: '/user',
    component: Userlayout,
    redirect: '/user',
    children: [
      {
        path: '/',
        name: '로그인',
        component: () => import('./views/user/index'),
        meta: { auth: false, title: '로그인', subTitle: '' }
      },
      {
        path: 'certificate',
        name: '본인인증',
        component: () => import('./views/user/certificate'),
        meta: { auth: false, title: '본인인증', subTitle: '' }
      },
      {
        path: 'join',
        name: '회원가입',
        component: () => import('./views/user/join'),
        meta: { auth: false, title: '회원가입', subTitle: '' }
      },
      {
        path: 'findPass',
        name: '비밀번호 찾기',
        component: () => import('./views/user/findPass'),
        meta: { auth: false, title: '비밀번호 찾기', subTitle: '' }
      },
      {
        path: 'Modal/CheckPlusSuccess',
        name: '본인인증성공',
        component: () => import('./views/user/Modal/CheckPlusSuccess'),
        meta: { auth: false, title: '본인인증성공', subTitle: '' }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    component: NotFound,
    meta: { auth: true }
  }
];

const router = new VueRouter({
  linkActiveClass: 'active',
  routes,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const auth = useAuthStore();
  if (to.meta.auth === true && auth.accessToken === null) {
    next('/');
    return;
  }
  return next();
});

export default router;
