/* eslint-disable no-undef */
export default {
  exCheck: async function () {
    let exId = localStorage.getItem('exId');
    try {
      let promiseResult = new Promise(resolve => {
        chrome.runtime.sendMessage(
          exId,
          {
            action: 'Check'
          },
          response => {
            resolve(response);
          }
        );
      });
      return await promiseResult;
    } catch (exception) {
      localStorage.removeItem('exId');
    }
  },

  GetMethod: async function (url, header, encodingType) {
    let exId = localStorage.getItem('exId');
    let promiseResult = new Promise(resolve => {
      chrome.runtime.sendMessage(
        exId,
        {
          action: 'Proxy_Search',
          url: url,
          headers: JSON.parse(header),
          encodingType
        },
        response => {
          resolve(response);
        }
      );
    });
    return await promiseResult;
  },
  PostMethod: async function (url, header, body, encodingType) {
    let exId = localStorage.getItem('exId');
    let promiseResult = new Promise(resolve => {
      chrome.runtime.sendMessage(
        exId,
        {
          action: 'Proxy_Search_Post',
          url: url,
          headers: JSON.parse(header),
          body: body,
          encodingType
        },
        response => {
          resolve(response);
        }
      );
    });
    return await promiseResult;
  },
  DeleteMethod: async function (url, header, body, encodingType) {
    let exId = localStorage.getItem('exId');
    let promiseResult = new Promise(resolve => {
      chrome.runtime.sendMessage(
        exId,
        {
          action: 'Proxy_Search_Delete',
          url: url,
          headers: JSON.parse(header),
          body: body,
          encodingType
        },
        response => {
          resolve(response);
        }
      );
    });
    return await promiseResult;
  },
  PatchMethod: async function (url, header, body, encodingType) {
    let exId = localStorage.getItem('exId');
    let promiseResult = new Promise(resolve => {
      chrome.runtime.sendMessage(
        exId,
        {
          action: 'Proxy_Search_Patch',
          url: url,
          headers: JSON.parse(header),
          body: body,
          encodingType
        },
        response => {
          resolve(response);
        }
      );
    });
    return await promiseResult;
  },
  PutMethod: async function (url, header, body, encodingType) {
    let exId = localStorage.getItem('exId');
    let promiseResult = new Promise(resolve => {
      chrome.runtime.sendMessage(
        exId,
        {
          action: 'Proxy_Search_Put',
          url: url,
          headers: JSON.parse(header),
          body: body,
          encodingType
        },
        response => {
          resolve(response);
        }
      );
    });
    return await promiseResult;
  },

  MultipartMethod: async function (url, header, body) {
    let exId = localStorage.getItem('exId');
    let promiseResult = new Promise(resolve => {
      chrome.runtime.sendMessage(
        exId,
        {
          action: 'Proxy_Search_Multipart',
          url: url,
          headers: JSON.parse(header),
          body: JSON.parse(body)
        },
        response => {
          resolve(response);
        }
      );
    });
    return await promiseResult;
  },

  ImageMethod: async function (url) {
    let exId = localStorage.getItem('exId');
    let promiseResult = new Promise(resolve => {
      chrome.runtime.sendMessage(
        exId,
        {
          action: 'Proxy_Search_Image',
          url: url
        },
        response => {
          resolve(response);
        }
      );
    });
    return await promiseResult;
  },
  CheckSiteLogin: async siteList => {
    let exId = localStorage.getItem('exId');
    let promiseResult = await new Promise(resolve => {
      chrome.runtime.sendMessage(exId, { action: 'CheckSiteLogin', url: siteList }, res =>
        resolve(res)
      );
    });
    return promiseResult;
  },
  GetBrowserHtmlMethod: async (url, header) => {
    let exId = localStorage.getItem('exId');
    let promiseResult = await new Promise(resolve => {
      chrome.runtime.sendMessage(
        exId,
        { action: 'GetBrowserHtml', url, headers: JSON.parse(header) },
        res => resolve(res)
      );
    });
    return promiseResult;
  }
};
