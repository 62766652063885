<template>
  <h1 class="title">{{ name }}</h1>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scope>
h1.title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  padding-bottom: 12px;
  border-bottom: 1px solid #c0c0c0;
}
</style>