import { JwtAxios } from '@/packaging';

export const weMakePriceApi = {
  async GetWeMakePriceShipmentPolicy(marketAccount, connectionId, shipmentPolicyNo) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetWeMakePriceShipmentPolicy?marketAccount=${marketAccount}&connectionId=${connectionId}&shipmentPolicyNo=${shipmentPolicyNo}`
    );
    return responseModel;
  },
  async GetWeMakePriceTemplate(marketAccount) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetWeMakePriceTemplate?marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async GetWeMakePriceShipmentPolicyList(connectionId, marketAccount) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetWeMakePriceShipmentPolicyList?connectionId=${connectionId}&marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async SetWeMakePriceAccount(connectionId, model) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/SetWeMakePriceAccount?connectionId=${connectionId}`,
      model
    );
    return responseModel;
  },
  async SetWeMakePriceTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetWeMakePriceTemplate`, model);
    return responseModel;
  },
  async GetWeMakePriceCaptchaInfo(id) {
    const res = await JwtAxios.get(`/Template/Market/GetWeMakePriceCaptchaInfo?connectionId=${id}`);
    return res;
  }
};
