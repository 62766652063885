<template>
  <div class="modal_wrap">
    <section class="stock_failLog">
      <div class="failLog_header">
        <div class="failLog_title">
          <h3 class="title">재고연동 로그</h3>
          <p class="description">재고연동 로그는 최대 3일까지 저장됩니다.</p>
        </div>
        <div class="failLog_pagination">
          <button>검색상품 관리</button>
          <Pagination :nowPage="searchRequest.page" :maxPage="maxPage" @movePage="movePage" />
        </div>
      </div>

      <div class="failLog_content">
        <div class="failLog_search_container">
          <div class="setting_row">
            <p class="setting_name">수집일자</p>
            <DatePicker
              type="date"
              range
              v-model="dateRange"
              format="YYYY-MM-DD"
              value-type="format"
            />
          </div>
          <div class="search_row">
            <select v-model="searchRequest.type">
              <option value="A">전체</option>
              <option value="CD">상품번호</option>
              <option value="FR">실패사유</option>
            </select>
            <input type="text" class="search_keyword" v-model="searchRequest.keyword" />
            <button @click="searchFailLog">검색</button>
          </div>
        </div>

        <ul class="failLog_table">
          <li class="table_row table_header">
            <div class="table_cell no">No.</div>
            <div class="table_cell reg_date">연동날짜</div>
            <div class="table_cell site">수집사이트</div>
            <div class="table_cell product_code">상품번호</div>
            <div class="table_cell market">마켓</div>
            <div class="table_cell fail_reason">메시지</div>
          </li>
          <li v-if="failLogList.length === 0" class="none_failLog">
            <p>실패로그가 없습니다.</p>
          </li>
          <li class="table_row" v-for="(log, index) in failLogList" :key="index">
            <div class="table_cell no">{{ index + 1 }}</div>
            <div class="table_cell reg_date">{{ log.regDate }}</div>
            <div class="table_cell site">{{ log.site }}</div>
            <div class="table_cell product_code">{{ log.code }}</div>
            <div class="table_cell market">{{ log.market }}</div>
            <div class="table_cell fail_reason">{{ log.failReason }}</div>
          </li>
        </ul>
      </div>

      <div class="button_group">
        <button class="submit_button" @click="$emit('closeModal')">닫기</button>
      </div>
    </section>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DatePicker from 'vue2-datepicker';
import { stockLinkAPI } from '@/api';
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      searchRequest: {
        startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        site: '',
        market: '',
        type: 'A',
        keyword: '',
        page: 1,
        limit: 10
      },
      dateRange: [dayjs().subtract(1, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
      failLogList: [],
      totalResult: 0,
      maxPage: 1
    };
  },
  methods: {
    async searchFailLog() {
      this.common.setLoading();
      const res = await stockLinkAPI.SearchStockLinkFailLog(this.searchRequest);
      this.common.setLoading();
      if (res.status !== 'Success') {
        this.common.setDialog({ subTitle: res.message ?? '에러가 발생했습니다.' });
        return;
      }
      this.failLogList = res.data.data;
      this.maxPage = res.data.maxPage;
      this.totalResult = res.data.totalResult;
      this.searchRequest.page = res.data.nowPage;
    },
    movePage(page) {
      this.searchRequest.page = page;
      this.searchFailLog();
    }
  },
  watch: {
    dateRange(date) {
      this.searchRequest.startDate = dayjs(date[0]).format('YYYY-MM-DD');
      this.searchRequest.endDate = dayjs(date[1]).format('YYYY-MM-DD');
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/inventoryLinked/stockFailLog.scss';
</style>