import { JwtAxios } from '@/packaging';

export const eleventstApi = {
  async GetElevenstTemplate(marketAccount) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetElevenstTemplate?marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async GetElevenstTransPolicy(marketAccount) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetElevenstTransPolicy?marketAccount=${marketAccount}`,
      null
    );
    return responseModel;
  },
  async GetElevenstReturnExchange(marketAccount) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetElevenstReturnExchange?marketAccount=${marketAccount}`,
      null
    );
    return responseModel;
  },
  async GetElevenstShipment(marketAccount) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetElevenstShipment?marketAccount=${marketAccount}`,
      null
    );
    return responseModel;
  },
  async SetElevenstTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetElevenstTemplate`, model);
    return responseModel;
  },
  async SetElevenstAccount(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetElevenstAccount`, model);
    return responseModel;
  }
};
