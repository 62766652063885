<template>
  <div class="deposit_detail">
    <div class="deposit_header">
      <h3 class="title">
        <svg_my_payment />
        <span>예치금 결제</span>
      </h3>
      <button class="close_button" @click="$emit('closeDepositDetail')"><svg_close /></button>
    </div>
    <div class="deposit_container">
      <div class="setting_row">
        <p class="setting_name">현재 예치금</p>
        <p class="deposit_price">{{ depositInfo.deposit.toLocaleString() }} 원</p>
      </div>
      <div class="setting_row recharge_deposit">
        <p class="setting_name">충전 예치금</p>
        <div class="option_group">
          <div class="recharge_price">
            <button @click="rechargeDeposit = Number(rechargeDeposit) + 10000">1만원</button>
            <button @click="rechargeDeposit = Number(rechargeDeposit) + 30000">3만원</button>
            <button @click="rechargeDeposit = Number(rechargeDeposit) + 50000">5만원</button>
            <button @click="rechargeDeposit = Number(rechargeDeposit) + 100000">10만원</button>
            <button @click="rechargeDeposit = 0">초기화</button>
          </div>
          <div class="input_group">
            <input type="number" v-model="rechargeDeposit" />
            <span class="unit">원</span>
          </div>
        </div>
      </div>
      <div class="setting_row">
        <p class="setting_name">충전 후 총 예치금</p>
        <p class="deposit_price">
          {{ (depositInfo.deposit + Number(rechargeDeposit)).toLocaleString() }} 원
        </p>
      </div>
    </div>
    <div class="payment_amount">
      <div class="setting_row">
        <p class="setting_name">최종 결제금액</p>
        <p class="deposit_price">{{ rechargeDeposit.toLocaleString() }} 원</p>
      </div>
      <div class="radio_group">
        <div class="radio_option">
          <input type="radio" v-model="payType" value="카드" id="카드" />
          <label for="카드">카드</label>
        </div>
        <div class="radio_option">
          <input type="radio" v-model="payType" value="가상계좌" id="가상계좌" />
          <label for="가상계좌">가상계좌</label>
        </div>
      </div>
      <div class="button_group">
        <button class="submit_button" @click="createPaymentId">결제하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import svg_my_payment from '@/components/svg/svg_my_payment';
import svg_close from '@/components/svg/svg_close';
import { paymentAPI } from '@/api';
export default {
  components: {
    svg_my_payment,
    svg_close
  },
  props: {
    depositInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      payType: '카드',
      rechargeDeposit: 0
    };
  },
  methods: {
    async createPaymentId() {
      if (this.rechargeDeposit < 10) {
        this.common.setToast('yellow', 'info', '요금을 입력해주세요.');
        return;
      }
      const paymentId = await paymentAPI.CreateDepositPaymentId({
        payType: this.payType,
        price: this.rechargeDeposit
      });

      if (paymentId.status !== 'Success') {
        this.common.setToast('yellow', 'info', paymentId.message);
        return;
      }
      const { user } = this.authStore;

      // eslint-disable-next-line no-undef
      await innopay.goPay({
        PayMethod: this.payType === '카드' ? 'CARD' : 'VBANK', // 결제수단(CARD,BANK,VBANK,CARS,CSMS,DSMS,EPAY,EBANK)
        MID: 'pgvidos02m', // 가맹점 MID
        MerchantKey:
          'Rb5b7DDUzT0VheO3U18CCwdrthPeOl/Q/oqxKT8lEUFGckbP4aB/pgrnlsGwRebrMoGoV91sm8YGWn6iYzjVRw==',
        GoodsName: '비도스(웹)', // 상품명
        Amt: String(this.rechargeDeposit), // 결제금액(과세)
        BuyerName: user.userName,
        BuyerTel: user.userTelNo, // 고객전화번호
        BuyerEmail: user.userEmail, // 고객이메일
        ResultYN: 'Y', // 결제결과창 출력유뮤,
        ReturnURL: `https://vidosw.co.kr${this.$route.fullPath}`,
        MallReserved: paymentId.data
      });
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/depositDetail.scss';
</style>
