<template>
  <div class="dropdown_box_conatiner">
    <input
      :type="inputType"
      @click="openDropdown = !openDropdown"
      class="dropdown_input"
      :value="inputValue"
      @input="updateInputValue($event.target.value)"
      @keypress="selectMenu(selectedMenu.value)"
    />

    <div
      class="dropdown_logo"
      :class="{ open_dropdown: openDropdown }"
      @click="openDropdown = !openDropdown"
    >
      <svg_dropdown />
    </div>

    <p
      v-if="selectedMenu !== undefined"
      class="selected_menu"
      @click="openDropdown = !openDropdown"
    >
      {{ selectedMenu.name }}
    </p>

    <ul class="dropdown_menu_list" v-if="openDropdown">
      <li
        v-for="menu in menuList"
        :key="menu.value"
        class="dropdown_menu"
        @click="selectMenu(menu.value)"
      >
        {{ menu.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import svg_dropdown from '@/components/svg/svg_dropdown';

export default {
  props: {
    menuList: {
      type: Array,
      default: () => []
    },
    inputType: {
      type: String,
      default: 'text'
    },
    inputValue: {
      type: Number,
      default: 0
    },
    menuValue: {
      type: String,
      default: ''
    },
    parentName: {
      type: String,
      default: ''
    }
  },
  components: {
    svg_dropdown
  },
  data() {
    return {
      openDropdown: false,
      selectedMenu: ''
    };
  },
  created() {
    this.setSelectedMenu();
  },
  methods: {
    setSelectedMenu() {
      this.selectedMenu = this.menuList.find(menu => menu.value === this.menuValue);
    },
    selectMenu(value) {
      if (this.selectedMenu.value !== value) {
        this.updateInputValue(0);
      }
      this.selectedMenu = this.menuList.find(menu => menu.value === value);
      this.$emit('updateMenuValue', value, this.parentName);
      this.openDropdown = false;
    },
    updateInputValue(value) {
      this.$emit('updateInputValue', value, this.parentName);
    }
  },
  watch: {
    menuValue() {
      this.setSelectedMenu();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/common/dropdownBox.scss';
</style>
