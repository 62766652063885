<template>
  <div class="alarm_container">
    <div class="alarm_header">
      <p class="total_count">총 0 건</p>
      <div class="alarm_options">
        <div class="switch_group input_switch">
          <p :class="{ alarm_text: alarmSwitch }">{{ alarmSwitch ? '알림 ON' : '알림 OFF' }}</p>
          <input role="switch" type="checkbox" v-model="alarmSwitch" />
        </div>
        <div class="read_button_group">
          <button disabled>모두 읽음으로 처리</button>
          <button disabled>읽음 삭제</button>
        </div>
      </div>
    </div>
    <div class="alarmh_body">
      <div class="none_alarm" v-if="true">
        <img src="/assets/images/app/layout/alert/alarm.png" alt="alarm" />
        <p>최근 받은 알림이 없습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alarmSwitch: true
    };
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/alarm.scss';
</style>