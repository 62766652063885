import { JwtAxios } from '@/packaging';
export const smartstoreApi = {
  async GetSmartStoreApiTemplate(marketAccount) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetSmartStoreApiTemplate?marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async GetSmartStoreApiShipment(marketAccount) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetSmartStoreApiShipment?marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async GetSmartStoreApiReturnExchange(marketAccount) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetSmartStoreApiReturnExchange?marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async SetSmartStoreApiTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetSmartStoreApiTemplate`, model);
    return responseModel;
  },
  async SetSmartStoreApi(body) {
    const responseModel = await JwtAxios.post('/Template/Market/SetSmartStoreApi', body);
    return responseModel;
  },
  async SetLotteOnApiTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetLotteOnApiTemplate`, model);
    return responseModel;
  }
};
