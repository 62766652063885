import { templateApi } from '@/api/template';
import { defineStore } from 'pinia';

export const useTemplateStore = defineStore({
  id: 'templateStore',
  state: () => ({
    isCalled: false,
    image: [],
    word: [],
    upload: []
  }),
  actions: {
    async setTemplateList() {
      this.isCalled = true;
      let promises = [
        templateApi.GetImageConfig(),
        templateApi.GetWordTemplateNameList(),
        templateApi.GetUploadConfig()
      ];

      try {
        let promiseResult = await Promise.all(promises);
        for (let template of promiseResult[0].data) {
          this.image.push({
            templateId: String(template.idx),
            templateName: template.templateName
          });
        }
        for (let template of promiseResult[1].data) {
          this.word.push({
            templateId: template.guid,
            templateName: template.templateName
          });
        }
        for (let template of promiseResult[2].data) {
          this.upload.push({
            templateId: String(template.idx),
            templateName: template.templateName
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async setImageTemplate() {
      this.image = [];
      const res = await templateApi.GetImageConfig();
      for (let template of res.data) {
        this.image.push({
          templateId: String(template.idx),
          templateName: template.templateName
        });
      }
    },
    async setUploadTemplate() {
      this.upload = [];
      const res = await templateApi.GetUploadConfig();
      for (let template of res.data) {
        this.upload.push({
          templateId: String(template.idx),
          templateName: template.templateName
        });
      }
    },
    async setWordTemplate() {
      this.word = [];
      const res = await templateApi.GetWordTemplateNameList();
      for (let template of res.data) {
        this.word.push({
          templateId: template.guid,
          templateName: template.templateName
        });
      }
    },
    clearTemplate() {
      this.word = [];
      this.upload = [];
      this.image = [];
      this.isCalled = false;
    }
  }
});
