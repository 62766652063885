import { JwtAxios } from '@/packaging';

export const coupangApi = {
  async GetCoupangTemplate(marketAccount) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetCoupangTemplate?marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async GetCoupangShipment(marketAccount, marketIdx) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetCoupangShipment?marketAccount=${marketAccount}&marketIdx=${marketIdx}`
    );
    return responseModel;
  },
  async GetCoupangReturnExchange(marketAccount, marketIdx) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetCoupangReturnExchange?marketAccount=${marketAccount}&marketIdx=${marketIdx}`
    );
    return responseModel;
  },
  async SetCoupangAccount(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetCoupangAccount`, model);
    return responseModel;
  },
  async SetCoupangTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetCoupangTemplate`, model);
    return responseModel;
  }
};
