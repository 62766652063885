import { JwtAxios } from '@/packaging';

export const auctionApi = {
  async GetAuctionTemplate(marketAccount) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetAuctionTemplate?marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async GetAuctionShipment(connectionId, marketAccount) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetAuctionShipment?connectionId=${connectionId}&marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async GetAuctionTransPolicy(body) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetAuctionTransPolicy?connectionId=${body.connectionId}&marketAccount=${body.marketAccount}&marketIdx=${body.marketIdx}&marketSubIdx=${body.marketSubIdx}&placeNo=${body.placeNo}`
    );
    return responseModel;
  },
  async GetAuctionReturnExchange(connectionId, marketAccount) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetAuctionReturnExchange?connectionId=${connectionId}&marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async SetAuctionAccount(id, body) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/SetAuctionAccount?connectionId=${id}`,
      body
    );
    return responseModel;
  },
  async SetAuctionTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetAuctionTemplate`, model);
    return responseModel;
  }
};
