import { JwtAxios } from '@/packaging';

export const categoryApi = {
  async SearchMappingCategory(keyword) {
    const responseModel = await JwtAxios.get(`/Category/SearchMappingCategory?keyword=${keyword}`);
    return responseModel;
  },
  async SearchCategory(market, keyword) {
    const responseModel = await JwtAxios.get(
      `/Category/SearchCategory?market=${market}&keyword=${keyword}`
    );
    return responseModel;
  }
};
