import { JwtAxios } from '@/packaging';

export const gmarketApi = {
  async GetGmarketTemplate(marketAccount) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetGmarketTemplate?marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async GetGmarketTransPolicy(body) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetGmarketTransPolicy?connectionId=${body.connectionId}&marketAccount=${body.marketAccount}&marketIdx=${body.marketIdx}&marketSubIdx=${body.marketSubIdx}&placeNo=${body.placeNo}`
    );
    return responseModel;
  },
  async GetGmarketShipment(connectionId, marketAccount) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetGmarketShipment?connectionId=${connectionId}&marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async GetGmarketReturnExchange(connectionId, marketAccount) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetGmarketReturnExchange?connectionId=${connectionId}&marketAccount=${marketAccount}`
    );
    return responseModel;
  },
  async SetGmarketTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetGmarketTemplate`, model);
    return responseModel;
  },
  async SetGmarketAccount(connectionId, model) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/SetGmarketAccount?connectionId=${connectionId}`,
      model
    );
    return responseModel;
  }
};
