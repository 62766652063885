import { JwtAxios } from '@/packaging';

export const accountAPI = {
  async UserCreate(model) {
    const responseModel = await JwtAxios.post(`/Account/UserCreate`, model);
    return responseModel;
  },
  async getTerms() {
    const responseModel = await JwtAxios.get(`/Account/GetTerms`);
    return responseModel;
  },
  async sendCertificationMail(model) {
    const responseModel = await JwtAxios.post('/Account/SendCertificationMail', model);
    return responseModel;
  },
  async checkCertificationMail(model) {
    const responseModel = await JwtAxios.post('/Account/CheckCertificationMail', model);
    return responseModel;
  },
  async findPassword(model) {
    const responseModel = await JwtAxios.post('/Account/FindPassword', model);
    return responseModel;
  },
  async GetUserRestrics() {
    const res = await JwtAxios.get('/Account/GetUserRestrics');
    return res;
  }
};
