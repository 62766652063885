import axios from 'axios';
import { useAuthStore } from '@/stores/auth';
import { useCommonStore } from '@/stores/common';

export const ImageJwtAxios = {
  multipart: multipart(),
  get: request('GET'),
  post: request('POST'),
  delete: request('DELETE')
};
function multipart() {
  return async (path, data) => {
    let url = process.env.VUE_APP_IMAEG_API_URL + path;
    const { accessToken } = useAuthStore();
    useCommonStore().setLoading();
    const res = await axios({
      method: 'POST',
      url,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + accessToken
      }
    })
      .then(handleResponse)
      .catch(handleError);
    useCommonStore().setLoading();

    return res;
  };
}
function request(method) {
  return (path, body) => {
    let url = process.env.VUE_APP_IMAEG_API_URL + path;
    const requestOptions = {
      method,
      headers: authHeader()
    };
    if (method === 'GET' && body) {
      url += '?' + jsonToQuery(body);
    } else if (
      (method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATCH') &&
      body
    ) {
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = JSON.stringify(body);
    }
    return axios({
      method,
      url,
      data: body,
      headers: requestOptions.headers
    })
      .then(handleResponse)
      .catch(handleError);
  };
}

function authHeader() {
  let resultModel = {};
  const { accessToken } = useAuthStore();
  if (accessToken !== null) {
    resultModel = { Authorization: `Bearer ${accessToken}` };
  }
  return resultModel;
}

function handleResponse(response) {
  const data = response.data;
  if (response.status !== 200) {
    const { refreshToken, logout } = useAuthStore();
    if ([401, 403].includes(response.status) && refreshToken) {
      logout();
    }

    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  return data;
}

function handleError(error) {
  console.error(error);
  let response = error.response;
  if (response === undefined) {
    return false;
  }
  let data = response.data;
  if ([401, 403].includes(response.status)) {
    // eslint-disable-next-line no-unused-vars
    const { logout } = useAuthStore();
    logout();
  }

  return data;
}

function jsonToQuery(param) {
  if (typeof param !== 'object') {
    return false;
  }

  var str = '';
  for (var key in param) {
    str += `${key}=${param[key]}&`;
  }

  return str;
}
