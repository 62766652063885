import { JwtAxios } from '@/packaging';

export const interparkApi = {
  async GetInterparkTemplate(marketAccount, vendorId, vendorNo) {
    const responseModel = await JwtAxios.get(
      `/Template/Market/GetInterparkTemplate?marketAccount=${marketAccount}&vendorId=${vendorId}&vendorNo=${vendorNo}`
    );
    return responseModel;
  },
  async GetInterparkTransPolicy(connectionId, marketAccount, vendorId, vendorNo) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/GetInterparkTransPolicy?connectionId=${connectionId}&marketAccount=${marketAccount}&vendorId=${vendorId}&vendorNo=${vendorNo}`,
      null
    );
    return responseModel;
  },
  async SetInterparkTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Market/SetInterparkTemplate`, model);
    return responseModel;
  },
  async SetInterparkAccount(connectionId, model) {
    const responseModel = await JwtAxios.post(
      `/Template/Market/SetInterparkAccount?connectionId=${connectionId}`,
      model
    );
    return responseModel;
  }
};
