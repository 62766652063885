<template>
  <div class="collectLog_wrap">
    <div class="collectLog_container">
      <div class="modal_head">
        <div class="modal_title">
          <h4 class="title">수집 실패 로그 보기</h4>
          <p class="collect_tooltip">수집로그는 최대 3일까지 저장됩니다.</p>
        </div>
        <Pagination :nowPage="0" :maxPage="0" @movePage="movePage" />
      </div>
      <div class="modal_options">
        <div class="log_options">
          <h6 class="collect_date">수집일자</h6>
          <DatePicker
            type="date"
            range
            format="YYYY-MM-DD"
            v-model="dateRange"
            value-type="format"
          />
        </div>
        <div class="search_wrap">
          <select v-model="options.keywordType">
            <option v-for="(type, index) in keywordType" :key="index" :value="type.value">
              {{ type.name }}
            </option>
          </select>
          <input type="text" class="search_input" v-model="options.keyword" />
          <button class="search_button" @click="getCollectLog">검색</button>
        </div>
      </div>
      <div class="modal_content">
        <div class="no_content" v-if="logList.length === 0">수집 실패로그가 없습니다.</div>
        <div class="failLog_content" v-else>
          <ul class="failLog_table">
            <li class="failLog_header">
              <div class="cell num">No.</div>
              <div class="cell collect_date">수집날짜</div>
              <div class="cell collect_name">수집설정명</div>
              <div class="cell collect_site">수집사이트</div>
              <div class="cell collect_all">전체</div>
              <div class="cell success_rating">성공/실패</div>
              <div class="cell openMore">더보기</div>
            </li>
            <div v-for="log in logList" :key="log.code">
              <li
                class="failLog_body"
                :class="{ open_fail_log: openMore === log.no }"
                @click="openMore = openMore === log.no ? '' : log.no"
              >
                <div class="cell num">{{ log.no }}</div>
                <div class="cell collect_date">
                  {{ collectLogDate(log.collectStartDate) }}
                </div>
                <div class="cell collect_name">{{ log.groupName }}</div>
                <div class="cell collect_site">{{ log.site }}</div>
                <div class="cell collect_all">{{ log.total }}</div>
                <div class="cell success_rating">{{ log.success }}/{{ log.fail }}</div>
                <div class="cell openMore">
                  <img
                    src="/assets/images/app/collectManagement/openMore.svg"
                    alt="more_btn"
                    v-if="openMore === log.no"
                  />
                  <img
                    src="/assets/images/app/collectManagement/closeMore.svg"
                    alt="more_btn"
                    v-else
                  />
                </div>
              </li>
              <ul v-if="openMore === log.no" class="failLog_detail">
                <li class="failLog_header" @click="openMore = ''">
                  <div class="cell num">No.</div>
                  <div class="cell collect_code">상품코드</div>
                  <div class="cell fail_reason">실패사유</div>
                  <div class="cell edit_date">작업일시</div>
                </li>
                <div v-for="(failLog, i) in log.failLogs" :key="i">
                  <li class="failLog_body">
                    <div class="cell num">{{ i + 1 }}</div>
                    <div class="cell collect_code" :title="failLog.code">{{ failLog.code }}</div>
                    <div class="cell fail_reason">{{ failLog.failReason }}</div>
                    <div class="cell edit_date">{{ failLog.regDate }}</div>
                  </li>
                </div>
              </ul>
            </div>
          </ul>
        </div>
        <div class="button_group">
          <button class="submit_button close_button" @click="$emit('closeCollectLog')">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import dayjs from 'dayjs';
import { collectApi } from '@/api';

export default {
  components: {
    DatePicker
  },
  props: {
    logType: {
      type: String,
      default: 'COLLECT'
    }
  },
  data() {
    return {
      options: {
        startDate: dayjs().subtract(3, 'day').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        keywordType: 'A',
        keyword: ''
      },
      keywordType: [
        { value: 'A', name: '전체' },
        { value: 'CD', name: '상품코드' },
        { value: 'GN', name: '수집 그룹명' }
      ],
      dateRange: [dayjs().subtract(3, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
      logList: [],
      openMore: ''
    };
  },
  methods: {
    movePage() {},
    async getCollectLog() {
      this.common.setLoading();
      const res = await collectApi.GetCollectFailLog(this.options);
      if (res.status === 'Success') {
        this.logList = res.data;
      }
      this.common.setLoading();
    }
  },
  computed: {
    collectLogDate() {
      return date => {
        return dayjs(date).format('YYYY.MM.DD');
      };
    }
  },
  watch: {
    dateRange(date) {
      this.options.startDate = dayjs(date[0]).format('YYYY-MM-DD');
      this.options.endDate = dayjs(date[1]).format('YYYY-MM-DD');

      this.getCollectLog();
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/productManagement/collectLog.scss';
</style>