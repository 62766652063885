import { JwtAxios } from '@/packaging';

export const authAPI = {
  async Login(model) {
    const responseModel = await JwtAxios.post(`/Auth/Login`, model);
    return responseModel;
  },
  async GetUser() {
    const responseModel = await JwtAxios.get(`/Auth/GetUser`);
    return responseModel;
  },
  async getPhoneAuthData() {
    const responseModel = await JwtAxios.get(`/Auth/GetPhoneAuthData`);
    return responseModel;
  },
  async ConfromPhoneAuthData(model) {
    const responseModel = await JwtAxios.post(`/Auth/ConfromPhoneAuthData`, model);
    return responseModel;
  }
};
