import { defineStore } from 'pinia';

export const useSocketStore = defineStore({
  id: 'socket_chat',
  state: () => ({
    connectionId: null,
    proxyRequestModel: null,
    proxyResponseModel: null,
    type: '',
    status: 'WAIT',
    finish: '',
    progress: 0,
    progressInterval: null,
    workList: [],
    collectList: [],
    uploadList: [],
    stockLinkList: [],
    soldOutLog: [],
    deleteResultList: [],
    deleteStatus: null,
    remainedDeleteMarket: []
  }),
  actions: {
    setConnectionId(id) {
      this.connectionId = id;
    },
    setType(type) {
      this.type = type;
    },
    setStatus(status) {
      this.status = status;
    },
    setWorkList(list) {
      this.workList = list;
    },
    setCollectList(reset, payload) {
      if (reset) {
        this.collectList.length = 0;
        return;
      }
      this.collectList.push(payload);
    },
    setUploadList(reset, payload) {
      if (reset) {
        this.uploadList.length = 0;
        return;
      }
      this.uploadList.push(payload);
    },
    setStockLinkList(reset, payload) {
      if (reset) {
        this.stockLinkList.length = 0;
        return;
      }
      this.stockLinkList.push(payload);
    },
    setProgress(type) {
      this.progress = localStorage.getItem('workStatusTime') ?? this.progress;
      if (type === 'start') {
        this.progressInterval = setInterval(() => {
          this.progress++;
          localStorage.setItem('workStatusTime', this.progress);
        }, 1000);
        return;
      } else if (type === 'pause') {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
      } else if (type === 'stop') {
        clearInterval(this.progressInterval);
        localStorage.removeItem('workStatusTime');
        this.progressInterval = null;
        this.progress = 0;
      }
      this.progressInterval = null;
    },
    setFinish(payload) {
      this.finish = payload;
    },
    clearDeleteResult() {
      this.deleteResultList = [];
    },
    setDeleteResult(log) {
      this.deleteResultList.push(log);
      this.deleteResultList.push('');
      this.deleteResultList = this.deleteResultList.filter(result => result !== '');
    },
    setSoldOutLog(log) {
      this.soldOutLog.push(log);
    },
    clearSoldOutLog() {
      this.soldOutLog = [];
    },
    setRemainedDeleteMarket(list) {
      this.remainedDeleteMarket = list;
    },
    setDeleteStatus(socketData) {
      this.deleteStatus = socketData;

      if (socketData === null) {
        return;
      }

      const { marketInfo } = socketData;
      this.remainedDeleteMarket = this.remainedDeleteMarket.filter(
        market =>
          market.market !== marketInfo.market ||
          market.marketAccount !== marketInfo.marketAccount ||
          market.marketIdx !== marketInfo.marketIdx ||
          market.marketSubIdx !== marketInfo.marketSubIdx
      );
    }
  }
});
