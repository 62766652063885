import useAuthStore from './stores/useAuthStore';
import useSocketStore from './stores/useSocketStore';
import useCommonStore from './stores/useCommonStore';
import useTemplateStore from './stores/useTemplateStore';
import useCollectStore from './stores/useCollectStore';
import stringEx from '@/mixin/globals/functions/stringEx';

export default {
  install(Vue) {
    Vue.mixin(stringEx);
    Vue.mixin(useAuthStore);
    Vue.mixin(useSocketStore);
    Vue.mixin(useCommonStore);
    Vue.mixin(useTemplateStore);
    Vue.mixin(useCollectStore);
  }
};
