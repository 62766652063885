import { JwtAxios } from '@/packaging';

export const templateApi = {
  async SetUploadConfig(model) {
    const responseModel = await JwtAxios.post(`/Template/Upload/SetUploadConfig`, model);
    return responseModel;
  },
  async GetUploadConfig() {
    const responseModel = await JwtAxios.get(`/Template/Upload/GetUploadConfig`);
    return responseModel;
  },
  async DeleteUploadConfig(idx) {
    const responseModel = await JwtAxios.delete(`/Template/Upload/deleteUploadConfig`, idx);
    return responseModel;
  },
  async GetImageConfig() {
    const responseModel = await JwtAxios.get(`/Template/Image/GetImageConfig`);
    return responseModel;
  },
  async DeleteImageConfig(idx) {
    const responseModel = await JwtAxios.delete(`/Template/Image/DeleteImageConfig`, idx);
    return responseModel;
  },
  async ChangePassWord(model) {
    const responseModel = await JwtAxios.post(
      `/Template/Setting/AccountInfo/ChangePassWord`,
      model
    );
    return responseModel;
  },
  async GetOpenmarketAccountList() {
    const responseModel = await JwtAxios.get(`/Template/Market/GetOpenmarketAccountList`);
    return responseModel;
  },
  async GetUserExchange() {
    const responseModel = await JwtAxios.get(`/Template/Setting/CurrencyInfo/GetUserExchange`);
    return responseModel;
  },
  async GetDefaultExchange() {
    const res = await JwtAxios.get(`/Template/Setting/CurrencyInfo/GetDefaultExchange `);
    return res;
  },
  async SetUserExchange(model) {
    const responseModel = await JwtAxios.post(
      `/Template/Setting/CurrencyInfo/SetUserExchange`,
      model
    );
    return responseModel;
  },
  async GetCollectPriceLimit() {
    const responseModel = await JwtAxios.get(`/Template/Setting/PriceLimit/GetCollectPriceLimit`);
    return responseModel;
  },
  async SetCollectPriceLimit(model) {
    const responseModel = await JwtAxios.post(
      `/Template/Setting/PriceLimit/SetCollectPriceLimit`,
      model
    );
    return responseModel;
  },
  async GetWordList(model) {
    const responseModel = await JwtAxios.post(`/Template/Word/GetWordList`, model);
    return responseModel;
  },
  async GetWordTemplateNameList() {
    const responseModel = await JwtAxios.get(`/Template/Word/GetWordTemplateNameList`);
    return responseModel;
  },
  async InputWord(model) {
    const responseModel = await JwtAxios.post(`/Template/Word/InputWord`, model);
    return responseModel;
  },
  async DeleteWordConfig(idx) {
    const responseModel = await JwtAxios.delete(`/Template/Word/DeleteWordConfig`, idx);
    return responseModel;
  },

  async SetWordTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Word/SetWordTemplate`, model);
    return responseModel;
  },
  async DownloadWordXls(type, guid, model) {
    const responseModel = await JwtAxios.excelMulitPartPost(
      `/Template/Word/DownloadWordXls?type=${type}&guid=${guid}`,
      model
    );
    return responseModel;
  },
  async UploadWordXlsx(type, guid, file) {
    const responseModel = await JwtAxios.post(
      `/Template/Word/UploadWordXlsx?type=${type}&guid=${guid}`,
      file
    );
    return responseModel;
  },
  async DeleteWord(idx) {
    const responseModel = await JwtAxios.delete(`/Template/Word/DeleteWord`, idx);
    return responseModel;
  },
  async DeleteWordTemplate(idx) {
    const responseModel = await JwtAxios.delete(`/Template/Word/DeleteWordTemplate`, idx);
    return responseModel;
  },
  async CopyWordTemplate(model) {
    const responseModel = await JwtAxios.post(`/Template/Word/CopyWordTemplate`, model);
    return responseModel;
  },
  async GetSuggestionWord(page, limit) {
    const responseModel = await JwtAxios.get(
      `/Template/Word/GetSuggestionWord?page=${page}&limit=${limit}`
    );
    return responseModel;
  },
  async SetImageConfig(model) {
    const responseModel = await JwtAxios.post(`/Template/Image/SetImageConfig`, model);
    return responseModel;
  },
  async getAddImageList(model) {
    const responseModel = await JwtAxios.post(
      '/Template/Setting/UserConfig/GetAddImageList',
      model
    );
    return responseModel;
  },
  async GetNaverKeywordAPI() {
    const responseModel = await JwtAxios.get('/Template/Setting/UserConfig/GetNaverKeywordAPI');
    return responseModel;
  },
  async SetNaverKeywordAPI(model) {
    const responseModel = await JwtAxios.post(
      '/Template/Setting/UserConfig/SetNaverKeywordAPI',
      model
    );
    return responseModel;
  },
  async GetUserInfo() {
    const res = await JwtAxios.get('/Template/Setting/AccountInfo/GetUserInfo');
    return res;
  },
  async UpdateUserInfo(body) {
    const res = await JwtAxios.post('/Template/Setting/AccountInfo/UpdateUserInfo', body);
    return res;
  },
  async GetOpenmarketAccountMaxCount() {
    const res = await JwtAxios.get(`/Template/Market/GetOpenmarketAccountMaxCount`);
    return res;
  },
  async GetMarketGroup() {
    const res = await JwtAxios.get(`/Template/Setting/UserConfig/GetMarketGroup`);
    return res;
  },
  async SetMarketGroup(body) {
    const res = await JwtAxios.post(`/Template/Setting/UserConfig/SetMarketGroup`, body);
    return res;
  },
  async RemoveMarketGroup(body) {
    const res = await JwtAxios.delete(`/Template/Setting/UserConfig/RemoveMarketGroup`, body);
    return res;
  },
  async GetCsTemplateNameList() {
    const res = await JwtAxios.post('/Template/Setting/CS/GetCsTemplateNameList');
    return res;
  },
  //CSTemplate
  async SetTemplate(body) {
    const res = await JwtAxios.post('/Template/Setting/CS/SetTemplate', body);
    return res;
  },
  async GetCsTemplate(idx) {
    const res = await JwtAxios.get(`/Template/Setting/CS/GetCsTemplate?idx=${idx}`);
    return res;
  },
  async GetExcelDownloadFormNameList() {
    const res = await JwtAxios.get(`/Template/Setting/OrderConfig/GetExcelDownloadFormNameList`);
    return res;
  },
  // OrderConfig
  async GetExcelColumnValueList() {
    const res = await JwtAxios.get(`/Template/Setting/OrderConfig/GetExcelColumnValueList`);
    return res;
  },
  async SetExcelDownLoadForm(body) {
    const res = await JwtAxios.post(`/Template/Setting/OrderConfig/SetExcelDownLoadForm`, body);
    return res;
  },
  async GetExcelDownloadForm(idx) {
    const res = await JwtAxios.get(`/Template/Setting/OrderConfig/GetExcelDownloadForm?idx=${idx}`);
    return res;
  },
  async DeleteExcelDownloadForm(body) {
    const res = await JwtAxios.delete(
      '/Template/Setting/OrderConfig/DeleteExcelDownloadForm',
      body
    );
    return res;
  },
  async GetFakeInvoice() {
    const res = await JwtAxios.get('/Template/Setting/OrderConfig/GetFakeInvoice');
    return res;
  },
  async SetFakeInvoice(body) {
    const res = await JwtAxios.post('/Template/Setting/OrderConfig/SetFakeInvoice', body);
    return res;
  },
  async GetMappingDeliveryCompanyList() {
    const res = await JwtAxios.get('/Template/Setting/OrderConfig/GetMappingDeliveryCompanyList');
    return res;
  },
  async GetMappingDeliveryCompanyIdx() {
    const res = await JwtAxios.get('/Template/Setting/OrderConfig/GetMappingDeliveryCompanyIdx');
    return res;
  },
  async SetMappingDeliveryCompany(body) {
    const res = await JwtAxios.post(
      '/Template/Setting/OrderConfig/SetMappingDeliveryCompany',
      body
    );
    return res;
  },
  async SendTelNoList() {
    const res = await JwtAxios.get(`/Template/Setting/SmsControl/SendTelNoList`);
    return res;
  },
  async DeleteSendTelNo(body) {
    const res = await JwtAxios.delete(`/Template/Setting/SmsControl/DeleteSendTelNo`, body);
    return res;
  },
  async AddSendTelNo(body) {
    const res = await JwtAxios.upload(`/Template/Setting/SmsControl/AddSendTelNo`, body);
    return res;
  },
  async DeleteOpenmarketAccount(idx) {
    const res = await JwtAxios.delete(`/Template/Market/DeleteOpenmarketAccount`, idx);
    return res;
  }
};
