import { JwtAxios } from '@/packaging';

export const marketProductApi = {
  async getDefaultPageData() {
    const res = await JwtAxios.get(`/MarketProduct/GetDefaultPageData`);
    return res;
  },
  async searchUploadProduct(body) {
    const res = await JwtAxios.post(`/MarketProduct/SearchUploadProduct`, body);
    return res;
  },
  async GetDeleteMarketList(body) {
    const res = await JwtAxios.post(`/MarketProduct/GetDeleteMarketList`, body);
    return res;
  },
  async GetDeleteList(body) {
    const res = await JwtAxios.post(`/MarketProduct/GetDeleteList`, body);
    return res;
  },
  async SetStatusChange(id, body) {
    const res = await JwtAxios.put(`/MarketProduct/SetStatusChange?connectionId=${id}`, body);
    return res;
  },
  async SetUpdateOption(body) {
    const res = await JwtAxios.patch('/MarketProduct/SetUpdateOption', body);
    return res;
  }
};
