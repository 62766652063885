import { ImageJwtAxios } from '@/packaging';

export const imageApi = {
  async GetDetailAddImageList() {
    const res = await ImageJwtAxios.get(`/image/GetDetailAddImageList`);
    return res;
  },
  async RemoveDetailAddImage(guid) {
    const res = await ImageJwtAxios.delete(`/image/RemoveDetailAddImage`, guid);
    return res;
  },

  async UploadDetailAddImage(name, guid, body) {
    const res = await ImageJwtAxios.multipart(
      `/image/UploadDetailAddImage?name=${name}&guid=${guid}`,
      body
    );
    return res;
  },
  async uploadMainImage({ guid, code, body }) {
    const res = await ImageJwtAxios.multipart(
      `/image/UploadMainImage?guid=${guid}&code=${code}`,
      body
    );
    return res;
  },
  async removeMainImage(guid) {
    const res = await ImageJwtAxios.delete(`/image/RemoveMainImage`, guid);
    return res;
  },
  async ImageInpaintingAi(image) {
    const res = await ImageJwtAxios.post('/image/ImageInpaintingAi', image);
    return res;
  }
};
