export default {
  methods: {
    //숫자필터
    filterNonNumeric(value) {
      return value.replace(/[^0-9]/g, '');
    },
    //사업자 검사
    checkBusinessNumber(str) {
      const regExp = /^[0-9]{3}-[0-9]{2}-[0-9]{5}$/;
      const regExp2 = /^[0-9]{10}$/;
      return regExp.test(str) || regExp2.test(str);
    },
    //핸드폰 검사
    checkPhone(str) {
      const regExp = /01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/;
      return regExp.test(str);
    },
    //이메일 검사
    checkEmail(str) {
      const regExp =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      return regExp.test(str);
    },
    //특문 검사
    checkSpecial(str) {
      const regExp = /[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi;
      return regExp.test(str);
    },
    //한글 체크
    checkKor(str) {
      const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
      return regExp.test(str);
    },
    // 숫자 체크
    checkNum(str) {
      const regExp = /[0-9]/g;
      return regExp.test(str);
    },
    // 영문(영어) 체크
    checkEng(str) {
      const regExp = /[a-zA-Z]/g; // 영어
      return regExp.test(str);
    },
    // 비밀번호 체크 (8~20자리 영문, 숫자, 특수문자 포함)
    checkPass(str) {
      const regExp = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
      return regExp.test(str);
    }
  }
};
