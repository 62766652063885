<template>
  <div class="log">
    <div class="log_header">
      <div class="button_group">
        <button
          class="log_type"
          :class="{ currentLog: currentLog === 'COLLECT' }"
          @click="currentLog = 'COLLECT'"
        >
          수집 로그
        </button>
        <button
          class="log_type"
          :class="{ currentLog: currentLog === 'UPLOAD' }"
          @click="currentLog = 'UPLOAD'"
        >
          업로드 로그
        </button>
        <button
          class="log_type"
          :class="{ currentLog: currentLog === 'STOCKLINK' }"
          @click="currentLog = 'STOCKLINK'"
        >
          재고 연동
        </button>
      </div>
      <button v-if="currentLog !== ''" @click="openDetailLog">로그 자세히 보기</button>
    </div>
    <div
      class="not_working"
      v-if="
        socketStore[listName[currentLog]] === undefined ||
        socketStore[listName[currentLog]].length === 0
      "
    >
      <img src="/assets/images/app/layout/alert/document.png" alt="not_log" />
      <p>진행중인 로그가 없습니다.</p>
    </div>

    <ul class="log_body" v-else>
      <li class="log_table_header">
        <div class="log_table_cell">
          {{ currentLog === 'COLLECT' ? '사이트' : '마켓' }}
        </div>
        <div class="log_table_cell">상품번호</div>
        <div class="log_table_cell">성공여부</div>
      </li>
      <div class="log_table_body">
        <div v-for="(log, index) in socketStore[listName[currentLog]]" :key="index">
          <li class="log_table_row" :class="{ open_failLog: failLogList.includes(index) }">
            <div class="log_table_cell site">
              <span>{{ index + 1 }}.</span>
              <p>{{ log.site }}</p>
            </div>
            <div class="log_table_cell">
              {{ logCode(log.code) ? log.code : '페이지 수집 실패' }}
            </div>
            <div class="log_table_cell isSucceed">
              <button v-if="log.isSuccess" class="success">
                <img src="/assets/images/app/layout/alert/smile.png" alt="isSucceed" />
                성공
              </button>
              <button v-else class="fail" @click="setFailLogList(index)">
                <img src="/assets/images/app/layout/alert/frown.png" alt="isSucceed" />
                실패
              </button>
            </div>
          </li>
          <li class="failReason" v-if="failLogList.includes(index)">
            <div
              class="failReason_box"
              :title="log.failReason === '' ? '페이지 수집을 실패했습니다.' : log.failReason"
            >
              {{ log.failReason === '' ? '페이지 수집을 실패했습니다.' : log.failReason }}
            </div>
          </li>
        </div>
      </div>
    </ul>
    <CollectLog v-if="openCollectLog" :logType="currentLog" @closeCollectLog="closeCollectLog" />
    <UploadLog v-if="openUploadLog" @closeModal="closeUploadLog" />
    <StockFailLog v-if="openStockLinkLog" @closeModal="closeStockLinkLog" />
  </div>
</template>

<script>
import CollectLog from '@/views/app/productManagement/components/CollectLog';
import UploadLog from '@/views/app/registeredProduct/components/UploadLog.vue';
import StockFailLog from '@/views/app/inventoryLinked/components/StockFailLog';

export default {
  components: {
    CollectLog,
    StockFailLog,
    UploadLog
  },
  data() {
    return {
      currentLog: '',
      listName: {
        COLLECT: 'collectList',
        UPLOAD: 'uploadList',
        STOCKLINK: 'stockLinkList'
      },
      failLogList: [],
      openCollectLog: false,
      openUploadLog: false,
      openStockLinkLog: false
    };
  },
  created() {
    this.currentLog = this.socketStore.type ?? '';
  },
  methods: {
    setFailLogList(index) {
      if (this.failLogList.includes(index)) {
        this.failLogList = this.failLogList.filter(idx => idx !== index);
      } else {
        this.failLogList.push(index);
      }
    },
    closeCollectLog() {
      this.openCollectLog = false;
    },
    closeUploadLog() {
      this.openUploadLog = false;
    },
    closeStockLinkLog() {
      this.openStockLinkLog = false;
    },
    openDetailLog() {
      const logType = {
        COLLECT: 'openCollectLog',
        UPLOAD: 'openUploadLog',
        STOCKLINK: 'openStockLinkLog'
      };
      this[logType[this.currentLog]] = true;
    }
  },
  computed: {
    logCode() {
      return code => {
        const regExp = /^https?:\/\//;
        return !regExp.test(code);
      };
    }
  },
  watch: {
    'socketStore.type': {
      deep: true,
      handler(type) {
        this.currentLog = type;
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/log.scss';
</style>
