import { JwtAxios } from '@/packaging';

export const statisticsApi = {
  getSalesByOpenMarket(year) {
    const res = JwtAxios.get(`/Statistics/GetSalesByOpenmarket?year=${year}`);
    return res;
  },
  getSalesByMarketGroup(year) {
    const res = JwtAxios.get(`/Statistics/GetSalesByMarketGroup?year=${year}`);
    return res;
  },
  getSalesBySite(year) {
    const res = JwtAxios.get(`/Statistics/GetSalesBySite?year=${year}`);
    return res;
  },
  getViews(model) {
    const res = JwtAxios.post('/Statistics/GetViews', model);
    return res;
  },
  getSalesRateByGroupName(model) {
    const res = JwtAxios.post('/Statistics/GetSalesRateByGroupName', model);
    return res;
  },
  getSalesRateByProductName(model) {
    const res = JwtAxios.post('/Statistics/GetSalesRateByProductName', model);
    return res;
  },
  GetDeleteMarketListByUpload(body) {
    const res = JwtAxios.post('/Statistics/GetDeleteMarketListByUpload', body);
    return res;
  },
  GetDeleteMarketListByCollect(body) {
    const res = JwtAxios.post('/Statistics/GetDeleteMarketListByCollect', body);
    return res;
  },
  GetDeleteListByViews(body) {
    const res = JwtAxios.post('/Statistics/GetDeleteListByViews', body);
    return res;
  },
  GetDeleteListByViewsAll(body) {
    const res = JwtAxios.post('/Statistics/GetDeleteListByViewsAll', body);
    return res;
  },
  GetDeleteListBySalesRateProductName(body) {
    const res = JwtAxios.post('/Statistics/GetDeleteListBySalesRateProductName ', body);
    return res;
  },
  GetDeleteListBySalesRateGroupName(body) {
    const res = JwtAxios.post('/Statistics/GetDeleteListBySalesRateGroupName', body);
    return res;
  },
  GetDeleteListBySalesRateProductNameAll(body) {
    const res = JwtAxios.post('/Statistics/GetDeleteListBySalesRateProductNameAll  ', body);
    return res;
  },
  GetDeleteListBySalesRateGroupNameAll(body) {
    const res = JwtAxios.post('/Statistics/GetDeleteListBySalesRateGroupNameAll', body);
    return res;
  },
  SetStatusChangeByUpload(id, body) {
    const res = JwtAxios.put(`/Statistics/SetStatusChangeByUpload?connectionId=${id}`, body);
    return res;
  },
  SetStatusChangeByCollect(id, body) {
    const res = JwtAxios.put(`/Statistics/SetStatusChangeByCollect?connectionId=${id}`, body);
    return res;
  }
};
