import { JwtAxios } from '@/packaging';

export const stockLinkAPI = {
  GetStockLinkSiteList: async type => {
    const res = JwtAxios.get(`/StockLink/GetStockLinkSiteList?type=${type}`);
    return res;
  },
  SearchStockLinkProduct: async body => {
    const res = JwtAxios.post(`/StockLink/SearchStockLinkProduct`, body);
    return res;
  },
  DoStockLink: async (id, body) => {
    const res = JwtAxios.post(`/StockLink/DoStockLink?id=${id}`, body);
    return res;
  },
  SearchStockLinkFailLog: async body => {
    const res = JwtAxios.post(`/StockLink/SearchStockLinkFailLog`, body);
    return res;
  }
};
