<template>
  <div>
    <transition-group name="pccToast" class="pccToast_wrap">
      <div
        class="toast"
        v-for="(toast, index) in toastList"
        :key="`${toast.color}${index}`"
        :class="toast.color"
      >
        <svg_good v-if="toast.icon === 'good'" />
        <svg_discontent v-else-if="toast.icon === 'discontent'" />
        <svg_info v-else-if="toast.icon === 'info'" />
        <svg_smile v-else-if="toast.icon === 'smile'" />
        <svg_scissors v-else-if="toast.icon === 'scissors'" />
        {{ toast.text }}
      </div>
    </transition-group>
  </div>
</template>

<script>
import svg_discontent from '@/components/svg/toast/svg_discontent';
import svg_good from '@/components/svg/toast/svg_good';
import svg_info from '@/components/svg/toast/svg_info';
import svg_smile from '@/components/svg/toast/svg_smile';
import svg_scissors from '@/components/svg/toast/svg_scissors';
export default {
  props: {
    toastList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    svg_discontent,
    svg_good,
    svg_info,
    svg_smile,
    svg_scissors
  },
  computed: {
    toastListLength() {
      return structuredClone(this.toastList).length;
    }
  },
  watch: {
    toastListLength() {
      setTimeout(() => {
        this.$emit('removeToast');
      }, 5000);
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/pccToast.scss';
</style>
