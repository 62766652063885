import { defineStore } from 'pinia';
import router from '@/router';
import { authAPI } from '@/api';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    user: null,
    encodeData: null,
    userName: null,
    userPhone: null
  }),
  actions: {
    async setupUser() {
      let response = await authAPI.GetUser();
      if (response.status == 'Success') {
        this.user = response.data;
      }
      return this.user;
    },

    async login(userId, password) {
      const responseModel = await authAPI.Login({
        userId: userId,
        password: password
      });
      if (responseModel.status == 'Success') {
        this.user = responseModel.data;
        this.accessToken = responseModel.data.accessToken;
        this.refreshToken = responseModel.data.refreshToken;
        localStorage.setItem('accessToken', this.accessToken);
        localStorage.setItem('refreshToken', this.refreshToken);
      }
      return responseModel;
    },

    logout() {
      this.accessToken = null;
      this.refreshToken = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      if (router.history.current.fullPath !== '/user') {
        router.push('/user');
      }
    },
    setEncodeData(data) {
      this.encodeData = data;
      router.push('/user/join');
    },
    setUserInfo(info) {
      this.userName = info.name;
      this.userPhone = info.phone;
    }
  }
});
