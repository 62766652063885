import { JwtAxios } from '@/packaging';

export const paymentAPI = {
  GetNowPayment: async () => {
    const res = await JwtAxios.get('/Payment/GetNowPayment');
    return res;
  },
  GetPeriodList: async () => {
    const res = await JwtAxios.get('/Payment/GetPeriodList');
    return res;
  },
  GetMaxProductsList: async () => {
    const res = await JwtAxios.get('/Payment/GetMaxProductsList');
    return res;
  },
  GetMaxAccountsList: async () => {
    const res = await JwtAxios.get('/Payment/GetMaxAccountsList');
    return res;
  },
  GetSourcingList: async (keyword, page, row) => {
    const res = await JwtAxios.get(
      `/Payment/GetSourcingList?keyword=${keyword}&page=${page}&row=${row}`
    );
    return res;
  },
  GetMarketList: async () => {
    const res = await JwtAxios.get('/Payment/GetMarketList');
    return res;
  },
  GetEtcServiceList: async () => {
    const res = await JwtAxios.get('/Payment/GetEtcServiceList');
    return res;
  },
  GetAddSourcingSitePrice: async () => {
    const res = await JwtAxios.get('/Payment/GetAddSourcingSitePrice');
    return res;
  },
  CreatePaymentId: async body => {
    const res = await JwtAxios.post('/Payment/CreatePaymentId', body);
    return res;
  },
  CreateDepositPaymentId: async body => {
    const res = await JwtAxios.post(`/Payment/CreateDepositPaymentId`, body);
    return res;
  }
};
